<template>
  <section class="section">
    <div>
      <h1 class="title has-text-centered">
        Unapproved Topics
      </h1>

      <topic-list :topics="topics.data"></topic-list>

      <b-pagination
        class="mt-4"
        v-model="topics.current_page"
        :total="topics.total"
        :per-page="topics.per_page"
        range-before="3"
        range-after="3"
        order="is-centered"
        icon-pack="fas"
        @change="handlePageChangeTopics"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      </b-pagination>
    </div>

    <div class="mt-6">
      <h1 class="title has-text-centered">
        Unapproved Comments
      </h1>

      <comment-list :comments="comments.data"></comment-list>

      <b-pagination
        class="mt-4"
        v-model="comments.current_page"
        :total="comments.total"
        :per-page="comments.per_page"
        range-before="3"
        range-after="3"
        order="is-centered"
        icon-pack="fas"
        @change="handlePageChangeComments"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      </b-pagination>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import TopicList from '@/components/Forum/TopicList.vue';
import CommentList from '@/components/Forum/CommentList.vue';

export default {
  name: 'Unapproved',
  components: { TopicList, CommentList },
  computed: {
    ...mapState('forum', ['topics', 'comments']),
  },
  mounted() {
    this.loadUnapproved();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadUnapproved();
    next();
  },
  methods: {
    loadUnapproved() {
      this.$store.dispatch('forum/loadUnapprovedTopics', 1);
      this.$store.dispatch('forum/loadUnapprovedComments', 1);
    },
    handlePageChangeTopics(pageNum) {
      this.$store.dispatch('forum/loadUnapprovedTopics', pageNum);
    },
    handlePageChangeComments(pageNum) {
      this.$store.dispatch('forum/loadUnapprovedComments', pageNum);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
