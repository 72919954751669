<template>
  <div>
    <b-table
      :data="topics"
      :striped="true"
      :hoverable="true"
      :loading="loadingTopics"
      class="mt-4 topic-table"
      :row-class="(row, index) => row.approved === 0 && 'is-danger'"
      @click="goToTopic"
    >
      <template v-slot:empty>
        <div class="has-text-centered">
          <h3>Nothing being discussed here yet...</h3>
        </div>
      </template>

      <b-table-column cell-class="small-column" field="user" label="Posted" v-slot="props">
        <p>
          <strong>{{ props.row.user.name }}</strong>
          <br />
          <small>{{ props.row.created_at | dateStandard }}</small>
        </p>
      </b-table-column>

      <b-table-column field="comments_count" label="Comments" v-slot="props">
        <p>
          <strong>{{ props.row.comments_count }}</strong>
        </p>
      </b-table-column>

      <b-table-column field="title" label="Title" v-slot="props">
        <p>
          <strong>{{ props.row.title }}</strong>
          <br />
          {{ props.row.text | excerpt }}
        </p>
      </b-table-column>

      <b-table-column field="status" label="Status" v-slot="props">
        <p>
          <b-field v-if="props.row.status">
            <b-tag :type="classByStatus(props.row.status)">{{ props.row.status }}</b-tag>
          </b-field>
          <b-field v-if="props.row.pinned">
            <b-tag type="is-warning">Pinned</b-tag>
          </b-field>
        </p>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'topic-list',
  props: {
    topics: Array,
  },
  computed: {
    ...mapState('forum', ['loadingTopics', 'selectedForum']),
  },
  methods: {
    goToTopic(row) {
      this.$router.push({ path: `/forums/${row.forum.slug}/${row.id}` });
    },
    classByStatus(status) {
      switch (status.toLowerCase()) {
        case 'open':
          return 'is-light';

        case 'to do':
          return 'is-info is-light';

        case 'in progress':
          return 'is-info';

        case 'fixed':
          return 'is-success';

        case 'won\'t do':
          return 'is-primary is-light';

        default:
          return 'is-light';
      }
    },
  },
};

</script>

<style type="text/scss">
  .topic-table tr {
    cursor: pointer;
  }
</style>
