<template>
  <div>
    <b-table
      :data="comments"
      :striped="true"
      :hoverable="true"
      :loading="loadingComments"
      class="mt-4"
      :row-class="(row, index) => !row.approved && 'is-danger'"
    >
      <template v-slot:empty>
        <div class="has-text-centered">
          <h3>No comments here yet...</h3>
        </div>
      </template>

      <b-table-column cell-class="small-column" field="user" label="Posted" v-slot="props">
        <p>
          <strong>{{ props.row.user.name }}</strong>
          <br />
          <small>{{ props.row.created_at | dateStandard }}</small>
        </p>
      </b-table-column>

      <b-table-column field="title" label="Comment" v-slot="props">
        <div class="comment-content">
          <div class="content" v-html="props.row.text"></div>

          <photo-list
            v-if="props.row.photos.length"
            type="comments"
            :id="props.row.id"
            :photos="props.row.photos"
            class="mt-2"
          ></photo-list>
        </div>
      </b-table-column>

      <b-table-column field="id" label="Actions" v-slot="props">
        <p class="comment-content">
          <b-button v-if="!props.row.approved" type="is-success" icon-left="thumbs-up" @click="approveComment(props.row.id)"></b-button>
          <b-button v-if="props.row.approved" type="is-warning" icon-left="thumbs-down" @click="unapproveComment(props.row.id)"></b-button>
          <b-button type="is-danger" icon-left="trash" @click="deleteComment(props.row.id)"></b-button>
        </p>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PhotoList from '@/components/Forum/PhotoList.vue';

export default {
  name: 'comment-list',
  components: { PhotoList },
  props: {
    comments: Array,
  },
  computed: {
    ...mapState('forum', ['loadingComments']),
  },
  methods: {
    approveComment(commentId) {
      this.$store.dispatch('forum/approveComment', commentId);
    },
    unapproveComment(commentId) {
      this.$store.dispatch('forum/unapproveComment', commentId);
    },
    deleteComment(commentId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting comment',
        message: 'Are you sure you want to <b>delete</b> this comment? This action cannot be undone.',
        confirmText: 'Delete Comment',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('forum/deleteComment', commentId)
            .then(() => {
              this.$buefy.toast.open('Comment deleted!');
            });
        },
      });
    },
  },
};
</script>

<style scoped>
  .comment-content {
    white-space: pre-wrap;
  }
</style>
