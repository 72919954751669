import { render, staticRenderFns } from "./PhotoList.vue?vue&type=template&id=d5b50cdc&scoped=true&"
import script from "./PhotoList.vue?vue&type=script&lang=js&"
export * from "./PhotoList.vue?vue&type=script&lang=js&"
import style0 from "./PhotoList.vue?vue&type=style&index=0&id=d5b50cdc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5b50cdc",
  null
  
)

export default component.exports