<template>
  <div>
    <b-message>
      <div
        class="thumbnail has-text-centered"
        v-for="photo in photos"
        :key="photo.id"
      >
        <img :src="photo.thumbnail" @click="showModal(photo)" />
        <b-button
          type="is-danger"
          size="is-small"
          icon-left="trash"
          @click="deletePhoto(photo.id)"
        ></b-button>
      </div>
    </b-message>

    <b-modal v-if="selectedPhoto" v-model="modalActive">
      <p class="image">
        <img :src="selectedPhoto.url">
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'photo-list',
  props: {
    type: String,
    id: Number,
    photos: Array,
  },
  data() {
    return {
      modalActive: false,
      selectedPhoto: null,
    };
  },
  methods: {
    showModal(photo) {
      this.selectedPhoto = photo;
      this.modalActive = true;
    },
    deletePhoto(photoId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting photo',
        message: 'Are you sure you want to <b>delete</b> this photo? This action cannot be undone.',
        confirmText: 'Delete Attachment',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const deleteAction = this.type === 'comments'
            ? this.$store.dispatch('forum/deleteCommentPhoto', {
              commentId: this.id,
              photoId,
            })
            : this.$store.dispatch('forum/deleteTopicPhoto', photoId);

          deleteAction.then(() => {
            this.$buefy.toast.open('Attachment deleted!');
          });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .thumbnail {
    display: inline-block;
    margin: 4px;
    cursor: pointer;

    img {
      display: block;
      border: 2px #ccc solid;
    }
  }
</style>
